<script setup>
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';

import Button from '@/Components/Button/Button.vue';
import GuestLayout from '@/Layouts/GuestLayout.vue';

let route = inject('route');

const props = defineProps({
    email: String,
    token: String,
});

const form = useForm({
    token: props.token,
    email: props.email,
    password: '',
    password_confirmation: '',
});

const submit = () => {
    form.post(route('password.update'), {
        onFinish: () => form.reset('password', 'password_confirmation'),
    });
};
</script>

<template>
    <GuestLayout :title="$t('Create {attribute}', { attribute: $t('password') })">
        <div class="container flex flex-col items-center w-full relative -top-[200px]">
            <div class="w-full p-8 bg-white rounded-2xl max-w-[550px] text-blue">
                <h1 class="text-[32px] font-bold mb-2">
                    {{ $t('Create {attribute}', { attribute: $t('password') }) }}
                </h1>
                <form @submit.prevent="submit">
                    <FormKit type="hidden" name="_token" v-model="form.token" />
                    <FormKit
                        type="email"
                        name="email"
                        label="E-mailadres"
                        validation="required|email"
                        v-model="form.email"
                    />

                    <FormKit type="password" name="password" label="Wachtwoord" v-model="form.password" />

                    <FormKit
                        type="password"
                        name="password_confirm"
                        label="Wachtwoord herhaling"
                        validation-label="Wachtwoord herhaling"
                        v-model="form.password_confirmation"
                    />

                    <div class="flex justify-end">
                        <Button
                            class="w-full"
                            type="button"
                            :processing="form.processing"
                            :recentlySuccessful="form.recentlySuccessful"
                            :text="$t('Log in')"
                            @click="submit()"
                        />
                    </div>
                </form>
            </div>
        </div>
    </GuestLayout>
</template>
